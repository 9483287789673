$breakpoint-mobile: 1320px;
$breakpoint-tabs: 570px;

@media (max-width: $breakpoint-tabs) {
  .fulfillment-tabs {
    .MuiTabs-flexContainer {
      flex-direction: column;
      align-items: center;
    }
  }
}

.pick-location-body {
  display: flex;

  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;
  }

  .picklocation-controls {
    display: block;
  }

  video#liveStream {
    display: block;
    border: 1px solid #555555;
    width: 100%;
  }

  .pick-location-settings {
    width: 100%;
  }
}

.delete-rack {
  cursor: pointer;

  &:hover {
    color: red;
  }
}

.delete-shelf {
  cursor: pointer;

  &:hover {
    color: red;
  }
}

.delete-location {
  cursor: pointer;

  &:hover {
    color: red;
  }
}

